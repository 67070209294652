import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const Features = ({ lang }) => {
  const { t } = useTranslation()
  return (
    <section id="features" className="features">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2
              className="features__title"
              dangerouslySetInnerHTML={{
                __html: t('page_home_section_1_title'),
              }}
            />
            <p className="features__subtitle">
              {t('page_home_section_1_subtitle')}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12 features__cards">
            <div className="features__card">
              <div className="features__card-image">
                <img src="/img/home/step/step-drag.svg" alt="" />
              </div>
              <div className="features__card-content">
                <h3
                  className="features__card-content--title"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_subtitle_1'),
                  }}
                />
                <p
                  className="text-primary secondary features__card-content-text"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_descr_1'),
                  }}
                />
              </div>
            </div>

            <div className="features__card ">
              <div className="features__card-image ">
                <img src="/img/home/step/step-edit.svg" alt="" />
              </div>
              <div className="features__card-content ">
                <h3
                  className="features__card-content--title"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_subtitle_2'),
                  }}
                />
                <p
                  className="text-primary secondary features__card-content-text"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_descr_2'),
                  }}
                />
              </div>
            </div>

            <div className="features__card">
              <div className="features__card-image">
                <img src="/img/home/step/step-cloud.svg" alt="" />
              </div>
              <div className="features__card-content">
                <h3
                  className="features__card-content--title"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_subtitle_3'),
                  }}
                />
                <p
                  className="text-primary secondary features__card-content-text"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_descr_3'),
                  }}
                />
              </div>
            </div>

            <div className="features__card">
              <div className="features__card-image">
                <img src="/img/home/step/step-devices.svg" alt="" />
              </div>
              <div className="features__card-content">
                <h3
                  className="features__card-content--title"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_subtitle_4'),
                  }}
                />
                <p
                  className="text-primary secondary features__card-content-text"
                  dangerouslySetInnerHTML={{
                    __html: t('page_home_section_1_descr_4'),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-12 text-center">
            <Link
              to={'/models'}
              className="features__btn features__btn--end btn btn-primary"
            >
              {t('page_home_section_1_btn')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
